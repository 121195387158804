import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import SurveyForm from './SurveyForm';
import Loading from './Loading'
function App() {
  return (
    <Router>
      <Routes>
      <Route path="/enquête/:id/:orderId/:clientId/:token" element={<Loading/>} />
      <Route path="/enquête-satisfaction" element={<SurveyForm/>} />
      </Routes>
    </Router>
  );
}
/**
 * 
 */
export default App;
