import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence, LayoutGroup } from 'framer-motion';
import Box from '@mui/material/Box';
import EmojiRating from './EmojiRating'; // Importez le composant EmojiRating
import AgeGroupRating from './AgeGroupRating'; // Importez le composant AgeGroupRating
import ReturnChancesRating from './ReturnChancesRating'; // Importez le composant ReturnChancesRating
import TemperatureRating from './TemperatureRating'; // Importez le composant TemperatureRating
import WaitTimeRating from './WaitingTimeRating';
import ReferralSourceRating from './ReferralSourceRating';
import OrderCard from './OrderCard';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import submitGif from './submit.gif';
import client from './client';
import { useParams } from 'react-router-dom';


const questions = [
  'Comment avez-vous entendu parler de nous ?',
  'De quelle tranche d\'âge faites-vous partie ?',
  'Donnez une note a chaque plat :',
  'L’accueil reçu à votre arrivée était-il chaleureux et courtois?',
  'Le délai d’attente était-il adéquat?',
  'À combien estimez-vous votre satisfaction globale vis-à-vis de nos services?',
  'À combien estimez-vous vos chances de revenir à notre restaurant?',
  'Donnez un avis au restaurant',
];
const formatDate = () => {
  const date = new Date()
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const SurveyForm = ({id, orderId, clientId,orders, link='', nameLink=''}) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState({});
  const [review, setReview] = useState('');
  const [submited, setSubmited] = useState(false);
  
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  console.log('Survey........' ,orders)

  const handleAnswer = (answer) => {
    console.log('currentQuestion........',currentQuestion, answer)
    setAnswers({ ...answers, [currentQuestion]: answer });
    setCurrentQuestion((prevQuestion) => prevQuestion + 1);
  };
  
  const submit = async () => {
    // Calculer le KPI
    let totalRating = 0;
    let numQuestions = 0;
  
    // Parcourir les réponses et calculer la somme des notes
    Object.keys(answers).forEach((question, index) => {
      if (index !== 0 && index !== 1 && index !== 2) { // Ignorer la première question
        totalRating += answers[question].rating;
        numQuestions++;
      }
      if(index == 2){
        answers[question].map((product)=>{
            totalRating += product.rating
            numQuestions++;
        })
      }
    });
  
    // Vérifier si des réponses ont été données à au moins une question
    if (numQuestions > 0) {
      const kpi = totalRating / numQuestions;
      const currentDate = new Date();
      const formattedTime = `${currentDate.getHours().toString().padStart(2, '0')}:${currentDate.getMinutes().toString().padStart(2, '0')}`;

      console.log('KPI:', kpi);
  
      // Soumettre les réponses
      await client.post('/give-review', {
        id_restaurant: id,
        orderId,
        clientId,
        answers,
        review,
        kpi,
        date: formatDate(),
        time: formattedTime, 
      },{
        headers: {
            Accept: 'application/json',
            //'content-Type': 'multipart/form-data',
          }
        }).then((res)=>{
        console.log('Réponses:', res.data);
        if(res.data.success) {setSubmited(true);
        handleAnswer({})
        }
      })

    } else {
      console.log('Aucune réponse donnée.');
    }
  };
  

  return (
    
    <Box
      sx={{
        height: '80vh',
        width: '80%',
        margin: 'auto',
        padding: '20px',
        position: 'relative',
        boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
        marginTop: '5%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Centre horizontalement
      }}
    >
      {/* Barre de progression */}
      <div
        style={{
          width: '80%', // Ajustez la largeur de la barre de progression
          display: 'flex',
          justifyContent: 'center', // Centre horizontalement
          alignItems: 'center', // Centre verticalement
        }}
      >
        {questions.map((question, index) => (
          <React.Fragment key={index}>
            {index > 0 && (
              <div
                style={{
                  height: '20%', // Ajustez la hauteur de la ligne
                  width: '5%', // Largeur de la ligne
                  backgroundColor: index <= currentQuestion ? '#ff3d47' : 'lightgray', // Couleur de la ligne
                  margin: '0 10px', // Ajustez la distance entre les lignes
                }}
              />
            )}
            <div
              className='bubble'
              style={{
                width: '30px', // Ajustez la largeur de la bulle
                height: '30px', // Ajustez la hauteur de la bulle
                borderRadius: '50%', // Forme de la bulle
                backgroundColor: index <= currentQuestion ? '#ff3d47' : 'lightgray', // Couleur de la bulle
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '12px', // Ajustez la taille de la police
                fontWeight: index === currentQuestion ? 'bold' : 'normal',
              }}
            >
              {index + 1}
            </div>
          </React.Fragment>
        ))}
      </div>
      <h2 style={{ marginBottom: '20px', textAlign: 'center' }}>{questions[currentQuestion]}</h2>

      {/* Liste de questions */}
      <LayoutGroup>
        <motion.ul layout initial={{ opacity: 0 }}>
          {questions.map((question, index) => (
            <motion.li
              key={index}
              onClick={() => setCurrentQuestion(index)}
              layout
              style={{
                fontWeight: currentQuestion === index ? 'bold' : 'normal',
              }}
            >
              {index + 1}
            </motion.li>
          ))}
        </motion.ul>
      </LayoutGroup>

      <AnimatePresence>
        {currentQuestion < questions.length && (
          <motion.div
            key={currentQuestion}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {currentQuestion === 0 && (
              <div style={{ position: 'absolute', top: '50%', left: '50%', width: '100%', transform: 'translate(-50%, -50%)' }}>
                <ReferralSourceRating handleAnswer={handleAnswer} />
              </div>
            )}
            {currentQuestion === 1 && (
              <div style={{ position: 'absolute', top: '50%', left: '50%', width: '100%', transform: 'translate(-50%, -50%)' }}>
                <AgeGroupRating handleAnswer={handleAnswer} />
              </div>
            )}
            {currentQuestion === 2 && (
              <div style={{ position: 'absolute', top: '50%', left: '50%', width: '100%', transform: 'translate(-50%, -50%)',marginTop:'10%',height:'50%' }}>
                <OrderCard orders={orders} handleAnswer={handleAnswer} />
              </div>
            )}
            {currentQuestion === 3 && (
              <div style={{ position: 'absolute', top: '50%', left: '50%', width: '100%', transform: 'translate(-50%, -50%)' }}>
                <TemperatureRating handleAnswer={handleAnswer} />
              </div>
            )}
            {currentQuestion === 4 && (
              <div style={{ position: 'absolute', top: '50%', left: '50%', width: '100%', transform: 'translate(-50%, -50%)' }}>
                <WaitTimeRating handleAnswer={handleAnswer} />
              </div>
            )}
            {currentQuestion === 5 && (
              <div style={{ position: 'absolute', top: '50%', left: '50%', width: '100%', transform: 'translate(-50%, -50%)' }}>
                <EmojiRating handleAnswer={handleAnswer} />
              </div>
            )}
            {currentQuestion === 6 && (
              <div style={{ position: 'absolute', top: '50%', left: '50%', width: '100%', transform: 'translate(-50%, -50%)' }}>
                <ReturnChancesRating handleAnswer={handleAnswer} />
              </div>
            )}
            {currentQuestion === 7 && (
              <div style={{ position: 'absolute', top: '50%', left: '50%', width: '100%', transform: 'translate(-50%, -50%)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <textarea
                  rows="4"
                  cols="50"
                  style={{
                    width: '80%', // Largeur par défaut
                    marginBottom: '20%', // Marge par défaut
                    '@media (maxWidth: 600px)': { // Media query pour les petits écrans
                      width: '90%', // Largeur ajustée pour les petits écrans
                      marginBottom: '10%', // Marge ajustée pour les petits écrans
                    },
                  }}
                  value={review}
                  onChange={(e) => setReview(e.target.value)}
                ></textarea>
                <Button variant='outlined' onClick={submit}>
                  Soumettre
                </Button>
              </div>
            )}
          </motion.div>
        )}
        {submited && (
          <motion.div
            style={{ position: 'absolute', top: '50%', left: '50%', width: '100%', transform: 'translate(-50%, -50%)', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
          >
            <img src={submitGif} alt="Merci pour votre soumission" />
            Merci pour votre soumission !!
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
  {link && link.length > 0 ? (
    <a
      href={link.startsWith('http') ? link : `https://${link}`}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
      <Button variant="contained" color="primary" style={{ marginTop: '20px' }}>
        Donner un avis sur {nameLink}
      </Button>
      <span style={{ marginTop: '10px', textAlign: 'center' }}>
        Ça nous aidera à faire connaître le restaurant
      </span>
    </a>
  ) : null}
</div>

            
          </motion.div>
        )}
      </AnimatePresence>
    </Box>
  );
};

export default SurveyForm;
