import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { useTheme, useMediaQuery } from '@mui/material';

const customIcons = {
  1: {
    icon: "🧊", // Très froid
    label: 'Très froid',
  },
  2: {
    icon: "❄️", // Froid
    label: 'Froid',
  },
  3: {
    icon: "⚖️", // Moyen
    label: 'Moyen',
  },
  4: {
    icon: "☀️", // Chaleureux et courtois
    label: 'Chaleureux et courtois',
  },
  5: {
    icon: "🔥", // Très chaleureux et courtois
    label: 'Très chaleureux et courtois',
  },
};

const StyledRadio = styled(Radio)(({ theme }) => ({
  '&.Mui-checked': {
    color: '#ff6d75',
  },
}));

function IconContainer(props) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { value, ...other } = props;
  const iconSize = isSmallScreen ? 60 : 50;
  const labelSize = isSmallScreen ? 16 : 16;

  const activeStyle = {
    transform: value === props.activeValue ? 'scale(1.5)' : 'scale(1)',
    transition: 'transform 0.3s ease-in-out',
    fontWeight: value === props.activeValue ? 'bold' : 'normal',
  };

  return (
<Box
      textAlign="center"
      {...other}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        ...activeStyle,
      }}
    >      <Box
        fontSize={iconSize}
        mt={-0.5}
        style={{ marginBottom: '5px' }}
      >
        {customIcons[value].icon}
      </Box>
      <Box fontSize={labelSize} mt={-0.5} color="rgba(192, 192, 192, )">
        {customIcons[value].label}
      </Box>
    </Box>
  );
}

export default function TemperatureRating({ handleAnswer }) {
  const [value, setValue] = useState();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, index) => {
    setValue(event.target.value);
    console.log("value......", index)
    setTimeout(() => {
      handleAnswer({rating:index, label:customIcons[index].label});
    }, 1000);
  };

  return (
<Box
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        gap: isSmallScreen ? '10px' : '20px',
      }}
    >      {Object.keys(customIcons).map((key) => (
        <StyledRadio
          key={key}
          checked={value === key}
          onChange={(event) => handleChange(event, parseInt(key))}
          value={key}
          name="emoji-radio-buttons"
          inputProps={{ 'aria-label': customIcons[key].label }}
          icon={<IconContainer value={parseInt(key)} activeValue={parseInt(value)} />}
          checkedIcon={<IconContainer value={parseInt(key)} activeValue={parseInt(value)} />}
        />
      ))}
    </Box>
  );
}


/**
 * TemperatureRating
 * const customIcons = {
  1: {
    icon: "🧊", // Très froid
    label: 'Très froid',
  },
  2: {
    icon: "❄️", // Froid
    label: 'Froid',
  },
  3: {
    icon: "⚖️", // Moyen
    label: 'Moyen',
  },
  4: {
    icon: "☀️", // Chaleureux et courtois
    label: 'Chaleureux et courtois',
  },
  5: {
    icon: "🔥", // Très chaleureux et courtois
    label: 'Très chaleureux et courtois',
  },
};
 */