import React, { useEffect, useState } from 'react';
import client from './client';
import { useParams, useNavigate } from 'react-router-dom'; // Importez useParams et useNavigate
import SurveyForm from './SurveyForm'; // Importez le composant SurveyForm

const Loading = () => {
    const navigate = useNavigate();
    const { id, orderId, clientId, token } = useParams(); // Utilisez useParams pour obtenir les valeurs des paramètres d'URL
    const [error, setError] = useState('Loading...')
    const [orders, setOrders] = useState([]); // État pour stocker les données récupérées
    const [link, setLink] = useState('')
    const [nameLink, setNameLink] = useState('')
    useEffect(() => {
        const fetchData = async () => {
            await client.post('/get-order', { id, orderId, clientId, token }).then((res) => {
                console.log('enquête-satisfaction........', res.data);
                if (res.data.success) {
                    setOrders(res.data.productInfo); // Mettez à jour l'état avec les données récupérées
                    setLink(res.data.link)
                    setNameLink(res.data.nameLink)
                } else {
                    setError(res.data.error)
                    // Gérer les cas d'erreur si nécessaire
                }
            });
        };
        fetchData();
    }, [id, orderId, clientId]);

    // Rendu conditionnel du composant SurveyForm une fois les données récupérées
    return orders&&orders.length>0 ? (
        <SurveyForm id={id} orderId={orderId} clientId={clientId} orders={orders} link={link} nameLink={nameLink} />
    ) : (
        <div>{error}</div>
    );
};

export default Loading;
