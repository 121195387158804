import React, { useState } from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import Rating from '@mui/material/Rating';
import avocat from './avocats.jpg';

const OrderCard = ({ orders, handleAnswer }) => {
  // Créer un tableau d'états pour stocker le rating de chaque commande
  const [ratings, setRatings] = useState(orders.map(() => 0));

  // Fonction pour mettre à jour le rating d'une commande spécifique
  const handleRatingChange = (index, newValue) => {
    const newRatings = [...ratings];
    newRatings[index] = newValue;
    setRatings(newRatings);
  };
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLastOrderRating = (index, newValue) => {
    handleRatingChange(index, newValue); // Mettre à jour le rating de la commande actuelle
    if (index === orders.length - 1) {
      // Vérifier si c'est la dernière commande
      const updatedRatings = [...ratings]; // Copier les valeurs de rating actuelles
      updatedRatings[index] = newValue; // Mettre à jour la valeur de rating de la dernière commande
      const ratingsArray = orders.map((order, i) => ({
        id_product: order.id_product,
        rating: updatedRatings[i], // Utiliser les valeurs mises à jour de rating
      }));
      setTimeout(() => {
        handleAnswer(ratingsArray); // Appeler handleAnswer avec les évaluations de toutes les commandes
      }, 1000);
    }
  };

  return (
    <Box
      sx={{
        maxHeight: '300px', // Définir une hauteur maximale pour la liste
        overflowY: 'auto', // Activer le défilement vertical lorsque le contenu dépasse la hauteur
      }}
    >
      {orders.map((order, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 2,
            padding: 2,
            border: '1px solid #ccc',
            borderRadius: '8px',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Ajouter une ombre avec une élévation
            width: isSmallScreen ? '85%' : '80%', // Largeur légèrement supérieure à 80% sur grand écran, sinon 90%
            marginLeft: isSmallScreen ? '3%' : '10%',
          }}
        >
          {/* Image */}
          <Box sx={{ marginRight: 2 }}>
            <img src={order.image} alt={order.name} style={{ width: '100px', height: 'auto' }} />
          </Box>

          {/* Product Name */}
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: isSmallScreen ? '90%' : 20 }}>{order.name}</Typography>
          </Box>

          {/* Rating */}
          <Box>
            <Rating
              name={`rating-${index}`}
              value={ratings[index]} // Utiliser le rating spécifique pour cette commande
              onChange={(event, newValue) => handleLastOrderRating(index, newValue)} // Mettre à jour le rating spécifique
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default OrderCard;
